// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
//import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "../stylesheets/application.scss"
import 'boxicons'
import '@fortawesome/fontawesome-free/js/all'

Rails.start()
//Turbolinks.start()
ActiveStorage.start()

$(function(){
    var timedifference = new Date().getTimezoneOffset();
    let tz_diff = 540 + timedifference;
    var expiration = new Date();
    expiration.setTime(expiration.getTime() + (3 * 60 * 60 * 1000)); // 3時間後のミリ秒を計算
    document.cookie = 'tz_diff=' + tz_diff + '; expires=' + expiration.toUTCString() + '; path=/';

    $('.save-theme').on('change', function() { // レイアウトの保存処理
        setTimeout(save_layout, 1000);
    });

    $('#resetBtn').on('click', function () { // リセットボタンでは、なぜかBODYのchangeイベントが発火しないので追加
        save_layout();
    });

    function save_layout() {
        let user_id = $('body').data('user-id');
        let json_data = {
            "data-layout-color": $('body').attr('data-layout-color'),
            "data-leftbar-theme": $('body').attr('data-leftbar-theme'),
            "data-layout-mode": $('body').attr('data-layout-mode'),
        }
        if ($('body').attr('data-leftbar-compact-mode') !== undefined) {
            json_data["data-leftbar-compact-mode"] = $('body').attr('data-leftbar-compact-mode');
        }
        $.ajax({
            url: '/users/' + user_id + '/save_layout',
            type: 'POST',
            dataType: 'json',
            data: JSON.stringify(json_data)
        }).done(function(data) {
        });
    }

    $('.lang-select').click(function(){
        let user_id = $('body').data('user-id');
        let json_data = {
            "lang": $(this).data('lang'),
        }
        $.ajax({
            url: '/users/' + user_id + '/save_lang',
            type: 'POST',
            dataType: 'json',
            data: JSON.stringify(json_data)
        }).done(function(data) {
            location.reload();
        });
    });
})
